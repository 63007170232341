import React from 'react';
import {useGlobal} from "reactn";
import "./NavBar.sass";
import {FiCalendar, FiMessageCircle, FiStar, FiUsers, FiSearch} from 'react-icons/fi';

const NavBar = () => {
    const me = useGlobal('user')[0];
    const [nav, setNav] = useGlobal('nav');

    return (
        <div className="nav-bar uk-flex">
            {me.level==='root' && <div className={`item${nav === 'rooms' ? ' active' : ''}`} onClick={() => setNav('rooms')}>
                <div className="icon">
                    <FiMessageCircle/>
                </div>
                <div className="text poppins-light blue-michigan">Salones</div>
            </div>}
            <div className={`item${nav === 'search' ? ' active' : ''}`} onClick={() => setNav('search')}>
                <div className="icon">
                    <FiSearch/>
                </div>
                <div className="text poppins-light blue-michigan">Buscar</div>
            </div>
            {me.level==='root' &&<>
            <div className={`item${nav === 'favorites' ? ' active' : ''}`} onClick={() => setNav('favorites')}>
                <div className="icon">
                    <FiStar/>
                </div>
                <div className="text poppins-light blue-michigan">Favoritos</div>
            </div>
            <div className={`item${nav === 'meetings' ? ' active' : ''}`} onClick={() => {
                setNav('meetings');
            }}>
                <div className="icon">
                    <FiUsers/>
                </div>
                <div className="text poppins-light blue-michigan">Meetings</div>
            </div>
            </>}
        </div>
    );
}

export default NavBar;
