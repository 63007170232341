import React, {useEffect} from 'react';
import {getGlobal, useGlobal} from 'reactn';
import './App.sass';
import {Switch, Route, Redirect, useLocation} from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Config from './config';
import {useDispatch, useSelector} from "react-redux";
import jwtDecode from 'jwt-decode';
import setAuthToken from "./actions/setAuthToken";
import initIO from "./actions/initIO";
import {setGlobal} from "reactn";
import {useToasts} from "react-toast-notifications";
import {parse} from 'query-string'
import Logout from "./pages/Logout";
import {useHistory} from "react-router-dom";
const App = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {addToast} = useToasts();
    const location = useLocation()
    const io = useSelector(state => state.io.io);
    const [user, setUser] = useGlobal('user');
    const token = useGlobal('token')[0];
    const setStartingPoint = useGlobal('entryPath')[1];
    const toHome = token && <Redirect to="/"/>;
    const toLogin = !token && <Redirect to="/login"/>;

    if (!['dark', 'light'].includes(Config.theme)) Config.theme = 'light';

    const logout = async () => {
        localStorage.removeItem('token');
        await token(null);
        await setUser({});
        addToast(`User logged out!`, {
            appearance: 'success',
            autoDismiss: true,
        })
        history.replace("/login");
    };

    React.useEffect(() => {
        const params = parse(location.search)
        if(params && location.pathname === '/' && params.u==='exit'){
            logout().then().catch();
            return
        }
        if (params && location.pathname === '/') {
            setGlobal({
                extCredentials: {
                    user: params?.u,
                    password: params?.p
                }
            }).then(() => {

            }).catch((err) => {
                console.error(err)
            })
        }


    }, [location])


    useEffect(() => {
        if (!io || !getGlobal().user || !token) return;
        let focusCount = 0;
        let interval = setInterval(() => {
            if (!document.hasFocus()) {
                focusCount++;
                if (focusCount === 10) {
                    io.emit('status', {status: 'away'});
                }
            } else if (focusCount !== 0) {
                focusCount = 0;
                io.emit('status', {status: 'online'});
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [io, token]);

    useEffect(() => {
        return () => {
            try {
                if (getGlobal().audioStream) getGlobal().audioStream.getTracks().forEach(track => track.stop());
            } catch (e) {
            }
            try {
                if (getGlobal().videoStream) getGlobal().videoStream.getTracks().forEach(track => track.stop());
            } catch (e) {
            }
        }
    }, []);

    if (!window.loaded) {
        setStartingPoint(window.location.pathname);
        const splitPath = window.location.pathname.split('/');
        const route = splitPath[1];
        const token = splitPath[2];
        if (route === 'login' && token && token.length > 20) {
            let decoded;
            try {
                decoded = jwtDecode(token);
                if (typeof decoded !== 'object' || typeof decoded.id !== 'string') return;
                setAuthToken(token);
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(decoded));
                setGlobal({
                    user: decoded,
                    token,
                }).then(() => {
                    dispatch(initIO(token));
                });
            } catch (e) {
                addToast("Invalid token provided in URL. You can still login manually.", {
                    appearance: 'error',
                    autoDismiss: true,
                });
            }
        }
        window.loaded = true
    }

    return (
        <div className={`theme ${Config.theme}`}>
            <Switch>
                <Route path="/forgot-password">
                    {toHome}
                    {!toHome && <ForgotPassword/>}
                </Route>
                <Route path="/login">
                    {toHome}
                    {!toHome && <Login/>}
                </Route>

                <Route path="/logout">
                    <Logout/>
                </Route>

                <Route path="/">
                    {toLogin}
                    {!toLogin && <Home/>}
                </Route>
            </Switch>
        </div>
    );
}

export default App;
