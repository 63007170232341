import React from 'react';
import "./TopBar.sass";
import {FiArrowLeft} from 'react-icons/fi';
import Picture from "../../../../components/Picture";
import {useGlobal} from "reactn";

const TopBar = () => {
    const setPanel = useGlobal('panel')[1];
    let room = {title: 'Group', isGroup: true};
    const title = useGlobal('groupTitle')[0];

    return (
            <div className="top-bar-group uk-flex uk-flex-between uk-flex-middle">
                <div className="nav">
                    <div className="button" onClick={() => setPanel('standard')}>
                        <FiArrowLeft/>
                    </div>
                    <div className="profile conversation poppins-bold blue-michigan">
                        <Picture user={room} group={room.isGroup} picture={room.picture} title={room.title}/>
                    </div>
                    <div className="text ">
                        <div className="title poppins-bold blue-michigan">Create Group</div>
                        <div className="message poppins-light blue-michigan">{title || 'Type a group name...'}</div>
                    </div>
                </div>
            </div>
    );
}

export default TopBar;
