import React, {useEffect} from 'react';
import Div100vh from 'react-div-100vh';

import {useGlobal} from "reactn";
import {useToasts} from "react-toast-notifications";
import {useHistory} from "react-router-dom";


const Logout = () => {
    const {addToast} = useToasts();
    const history = useHistory();
    const [user, setUser] = useGlobal('user');
    const setToken = useGlobal('token')[1];


    useEffect(() => {
        logout().then().catch()
    }, [])

    const logout = async () => {
        const username = user.username;
        localStorage.removeItem('token');
        await setToken(null);
        await setUser({});
        addToast(`User ${username} logged out!`, {
            appearance: 'success',
            autoDismiss: true,
        })
        history.replace("/login");
    };

    return (
        <Div100vh>
            <h2>{user.username} is LogOut</h2>
        </Div100vh>
    );
}

export default Logout;
