import axios from "axios";
import Config from "../config";

const search = (text, limit,type) => {

    let level = type;
 
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/search",
        data: { limit: limit || 1000, search: text || '',type:level }
    });
};

export default search;
